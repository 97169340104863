import React from 'react'
import { dateToLocaleString } from '../../../../helpers/dateToLocaleString'

const FeaturedArticles = ({ articles }) => {
  const featuredPosts = articles
    .map((p) => {
      const [day, month, year] = p.date.split('/')
      const stringDate = `${month}/${day}/${year}`
      const timestamp = new Date(stringDate)

      return { ...p, date: timestamp }
    })
    .sort((a, b) => b.date - a.date)
    .splice(0, 5)

  return (
    <aside className="blog-post__featured-articles">
      <h3 className="blog-post__featured-articles__title">Artigos em destaque</h3>
      {featuredPosts.map(({ date, url, title, id }) => (
        <div className="blog-post__featured-articles__article" key={id}>
          <span className="blog-post__featured-articles__date">
            {dateToLocaleString(date)}
          </span>
          <br />
          <a href={url} className="blog-post__featured-articles__link">
            {title}
          </a>
        </div>
      ))}
    </aside>
  )
}

export default FeaturedArticles;
