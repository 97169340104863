import React from 'react'
import Button from '../../../components/molecules/Button'
import { renderContentfulElements } from '../../blog/post/content'

const ContentWrapper = ({ subtitle, children, modifier }) => {
  return (
    <div className={`job__content-wrapper job__content-wrapper${modifier}`}>
      <h2 className="job__subtitle">{subtitle}</h2>
      {children}
    </div>
  )
}

const JobContent = ({
  title,
  benefitsHtmlElements,
  skillsHtmlElements,
  hardSkillsHtmlElements,
  differentialsHtmlElements,
  salary,
  description,
  aboutCompany,
  operatingModel,
  contractModel,
  url,
  vacancyType,
  location,
}) => {
  return (
    <article className="job">
      <h1 className="job__title">{title}</h1>

      <ContentWrapper subtitle="Modelo de atuação:" modifier={'--horizontal'}>
        <p>
          {operatingModel || ''} - {location || ''}
        </p>
      </ContentWrapper>

      <ContentWrapper subtitle="Modelo de contratação:" modifier={'--horizontal'}>
        <p>
          {contractModel || ''} - {vacancyType || ''}
        </p>
      </ContentWrapper>
      {salary !== undefined ? (
        <ContentWrapper subtitle="Remuneração:" modifier={'--horizontal'}>
          {salary}
        </ContentWrapper>
      ) : null}

      {benefitsHtmlElements !== undefined ? (
        <ContentWrapper subtitle="Benefícios:" modifier={'--vertical'}>
          {benefitsHtmlElements?.map((el) =>
            renderContentfulElements(el.type, el.componentContent.value),
          )}
        </ContentWrapper>
      ) : null}

      <ContentWrapper subtitle="Conheça o nosso desafio/projeto:" modifier={'--vertical'}>
        {description}
      </ContentWrapper>

      {skillsHtmlElements !== undefined ? (
        <ContentWrapper subtitle="Competências comportamentais:" modifier={'--vertical'}>
          {skillsHtmlElements?.map((el) =>
            renderContentfulElements(el.type, el.componentContent.value),
          )}
        </ContentWrapper>
      ) : null}
      {hardSkillsHtmlElements !== undefined ? (
        <ContentWrapper subtitle="Competências técnicas:" modifier={'--vertical'}>
          {hardSkillsHtmlElements?.map((el) =>
            renderContentfulElements(el.type, el.componentContent.value),
          )}
        </ContentWrapper>
      ) : null}

      {differentialsHtmlElements !== undefined ? (
        <ContentWrapper subtitle="Habilidades valorizadas:" modifier={'--vertical'}>
          {differentialsHtmlElements?.map((el) =>
            renderContentfulElements(el.type, el.componentContent.value),
          )}
        </ContentWrapper>
      ) : null}

      {aboutCompany !== undefined ? (
        <ContentWrapper subtitle="Conheça a Digital Republic:" modifier={'--vertical'}>
          {aboutCompany
            .split(/\r?\n/)
            .map((about, index) => about.length > 0 && <p key={index}>{about}</p>)}
        </ContentWrapper>
      ) : null}

      <div className="job__button-wrapper">
        <Button
          classButton={'primary'}
          fontColor={'orange-dark'}
          text={'Candidatar-se'}
          link={url}
        />
      </div>
    </article>
  )
}

export default JobContent
