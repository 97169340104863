import React from 'react'
import ShareOptions from '../share-options'
import FeaturedArticles from '../featured-ariticles'
import {
  Paragraph,
  Headline2,
  Headline3,
  OrderedList,
  PostImage,
  UnorderedList,
} from '../contentful-elements'
import { v4 as uuidv4 } from 'uuid'

const LinkedinIcon = ({ url }) => (
  <a
    href={url}
    target="_blank"
    rel="noreferrer"
    className="blog-post__article__author__linkedin"
  >
    <svg viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.90991 13.1252H0.214732V4.3625H2.90991V13.1252ZM1.56087 3.16719C0.69904 3.16719 0 2.44648 0 1.57637C6.16861e-09 1.15842 0.164448 0.757587 0.457168 0.462052C0.749888 0.166518 1.1469 0.000488281 1.56087 0.000488281C1.97484 0.000488281 2.37185 0.166518 2.66457 0.462052C2.95729 0.757587 3.12174 1.15842 3.12174 1.57637C3.12174 2.44648 2.42241 3.16719 1.56087 3.16719ZM12.9971 13.1252H10.3077V8.85957C10.3077 7.84297 10.2874 6.53926 8.90645 6.53926C7.50518 6.53926 7.29045 7.64375 7.29045 8.78633V13.1252H4.59817V4.3625H7.18308V5.55781H7.2208C7.58062 4.86934 8.45958 4.14277 9.77089 4.14277C12.4986 4.14277 13 5.95625 13 8.31172V13.1252H12.9971Z"
        fill="#000000"
      />
    </svg>
  </a>
)

export const renderContentfulElements = (label, value) => {
  const elementKey = uuidv4()

  return {
    paragraph: <Paragraph text={value} key={elementKey} />,
    'heading-2': <Headline2 text={value} key={elementKey} />,
    'heading-3': <Headline3 text={value} key={elementKey} />,
    'ordered-list': <OrderedList items={value} key={elementKey} />,
    'unordered-list': <UnorderedList items={value} key={elementKey} />,
    'embedded-asset-block': <PostImage image={value} key={elementKey} />,
  }[label]
}

const Content = ({ elements, title, date, category, authors, url, featuredPosts }) => {
  const currCategory = category.includes(' ')
    ? category.substring(0, category.indexOf(' '))
    : category.toLowerCase()

  return (
    <div className="blog-post__article__wrapper">
      <div>
        <article className="blog-post__article">
          <h1 className="blog-post__article__title">{title}</h1>
          <span className="blog-post__article__date">{date}</span>
          <p
            className={`blog-post__article__category blog-post__article__category-${currCategory.toLowerCase()}`}
          >
            {category}
          </p>
          <div className="blog-post__article__text">
            {elements.map((el) =>
              renderContentfulElements(el.type, el.componentContent.value),
            )}
          </div>
          {!!authors.length && (
            <div className="blog-post__article__author">
              {authors.map(({ name, role, url }, index, arr) => (
                <React.Fragment key={`${name}_${role}`}>
                  {index === 0 && (
                    <b>{arr.length > 1 ? 'Autores' : 'Autor'} do artigo:</b>
                  )}
                  <div style={{ marginBottom: '15px' }}>
                    <p style={{ marginBottom: '10px' }}>
                      {name} / {role}
                    </p>
                    {url && <LinkedinIcon url={url} />}
                  </div>
                </React.Fragment>
              ))}
            </div>
          )}
        </article>
        <ShareOptions url={url} />
      </div>
      <FeaturedArticles articles={featuredPosts} />
    </div>
  )
}

export default Content
