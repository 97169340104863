import { GatsbyImage } from 'gatsby-plugin-image'
import React, { useId } from 'react'
import { formatSrcToGatsbyImage } from '../../../../helpers/formatSrcToGatsbyImage'
import parse from 'html-react-parser'

const Paragraph = ({ text }) => <p dangerouslySetInnerHTML={{ __html: text }} />

const Headline2 = ({ text }) => <h2 dangerouslySetInnerHTML={{ __html: text }} />

const Headline3 = ({ text }) => <h3 dangerouslySetInnerHTML={{ __html: text }} />

const OrderedList = ({ items }) => (
  <ul>
    {items?.map((item) => (
      <li>
        <p dangerouslySetInnerHTML={{ __html: item }} />
      </li>
    ))}
  </ul>
)

const UnorderedListItem = ({ item }) => {
  const id = useId()
  const value = parse(item)
  return (
    <li key={id}>
      <span>{value}</span>
    </li>
  )
}

const UnorderedList = ({ items }) => (
  <ul>
    {items?.map((item) => (
      <UnorderedListItem item={item} />
    ))}
  </ul>
)

const PostImage = ({ image }) => (
  <>
    <GatsbyImage image={formatSrcToGatsbyImage(image.url)} alt={image.title} />
    {image.caption && <span>{image.caption}</span>}
  </>
)

export { Paragraph, Headline2, Headline3, OrderedList, PostImage, UnorderedList }
