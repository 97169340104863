import React, { useState } from 'react'
import { useLocation } from '@reach/router'

const ShareOptions = ({ url }) => {
  const location = useLocation()
  const [wasUrlCopied, setWasUrlCopied] = useState(false)
  const DEFAULT_ORIGIN = location.origin
  const shareUrl = `${DEFAULT_ORIGIN}${url}`

  const onCopyToClipboard = async () => {
    await navigator.clipboard.writeText(shareUrl).then(() => setWasUrlCopied(true))
  }

  return (
    <div className="blog-post__share">
      <span>Compartilhe</span>
      <a
        href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`}
        target="_blank"
      >
        <svg viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.85156 8.4375L8.2515 5.72285H5.75091V3.96123C5.75091 3.21855 6.10022 2.49463 7.22016 2.49463H8.35697V0.183398C8.35697 0.183398 7.32534 0 6.339 0C4.27969 0 2.93362 1.3002 2.93362 3.65391V5.72285H0.644531V8.4375H2.93362V15H5.75091V8.4375H7.85156Z"
            fill="#000000"
          />
        </svg>
      </a>
      <a
        href={`https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}`}
        target="_blank"
      >
        <svg viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M2.90991 13.1252H0.214732V4.3625H2.90991V13.1252ZM1.56087 3.16719C0.69904 3.16719 0 2.44648 0 1.57637C6.16861e-09 1.15842 0.164448 0.757587 0.457168 0.462052C0.749888 0.166518 1.1469 0.000488281 1.56087 0.000488281C1.97484 0.000488281 2.37185 0.166518 2.66457 0.462052C2.95729 0.757587 3.12174 1.15842 3.12174 1.57637C3.12174 2.44648 2.42241 3.16719 1.56087 3.16719ZM12.9971 13.1252H10.3077V8.85957C10.3077 7.84297 10.2874 6.53926 8.90645 6.53926C7.50518 6.53926 7.29045 7.64375 7.29045 8.78633V13.1252H4.59817V4.3625H7.18308V5.55781H7.2208C7.58062 4.86934 8.45958 4.14277 9.77089 4.14277C12.4986 4.14277 13 5.95625 13 8.31172V13.1252H12.9971Z"
            fill="#000000"
          />
        </svg>
      </a>
      <a href={`http://twitter.com/share?url=${shareUrl}`} target="_blank">
        <svg viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M14.3553 3.74113C14.3655 3.88325 14.3655 4.02541 14.3655 4.16753C14.3655 8.50253 11.066 13.4975 5.03553 13.4975C3.17766 13.4975 1.45178 12.9594 0 12.0254C0.263969 12.0558 0.51775 12.066 0.791875 12.066C2.32484 12.066 3.73603 11.5483 4.86294 10.665C3.42131 10.6345 2.21319 9.69038 1.79694 8.39088C2 8.42131 2.20303 8.44163 2.41625 8.44163C2.71066 8.44163 3.00509 8.401 3.27919 8.32997C1.77666 8.02538 0.649719 6.7056 0.649719 5.11169V5.07109C1.08625 5.31475 1.59391 5.46703 2.13194 5.48731C1.24869 4.89847 0.670031 3.89341 0.670031 2.75634C0.670031 2.14722 0.832438 1.58884 1.11672 1.10153C2.73094 3.09138 5.15734 4.39084 7.87813 4.533C7.82738 4.28934 7.79691 4.03556 7.79691 3.78175C7.79691 1.97463 9.25884 0.502563 11.0761 0.502563C12.0203 0.502563 12.873 0.898501 13.472 1.53809C14.2131 1.39597 14.9238 1.12184 15.5533 0.74622C15.3096 1.50766 14.7918 2.14725 14.1116 2.55331C14.7715 2.48228 15.4111 2.2995 15.9999 2.04572C15.5533 2.69544 14.9949 3.27409 14.3553 3.74113Z"
            fill="#000000"
          />
        </svg>
      </a>
      <a href={`https://api.whatsapp.com/send?text=${shareUrl}`} target='_blank'>
        <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.9031 2.03437C10.5938 0.721875 8.85 0 6.99687 0C3.17187 0 0.059375 3.1125 0.059375 6.9375C0.059375 8.15938 0.378125 9.35312 0.984375 10.4062L0 14L3.67812 13.0344C4.69063 13.5875 5.83125 13.8781 6.99375 13.8781H6.99687C10.8188 13.8781 14 10.7656 14 6.94063C14 5.0875 13.2125 3.34687 11.9031 2.03437ZM6.99687 12.7094C5.95937 12.7094 4.94375 12.4312 4.05937 11.9062L3.85 11.7812L1.66875 12.3531L2.25 10.225L2.1125 10.0063C1.53437 9.0875 1.23125 8.02812 1.23125 6.9375C1.23125 3.75938 3.81875 1.17188 7 1.17188C8.54062 1.17188 9.9875 1.77187 11.075 2.8625C12.1625 3.95312 12.8313 5.4 12.8281 6.94063C12.8281 10.1219 10.175 12.7094 6.99687 12.7094ZM10.1594 8.39062C9.9875 8.30313 9.13438 7.88438 8.975 7.82812C8.81563 7.76875 8.7 7.74062 8.58438 7.91562C8.46875 8.09062 8.1375 8.47813 8.03438 8.59688C7.93438 8.7125 7.83125 8.72812 7.65938 8.64062C6.64062 8.13125 5.97188 7.73125 5.3 6.57812C5.12188 6.27187 5.47812 6.29375 5.80937 5.63125C5.86562 5.51562 5.8375 5.41563 5.79375 5.32812C5.75 5.24062 5.40313 4.3875 5.25938 4.04063C5.11875 3.70312 4.975 3.75 4.86875 3.74375C4.76875 3.7375 4.65312 3.7375 4.5375 3.7375C4.42188 3.7375 4.23438 3.78125 4.075 3.95312C3.91562 4.12813 3.46875 4.54688 3.46875 5.4C3.46875 6.25313 4.09063 7.07813 4.175 7.19375C4.2625 7.30938 5.39687 9.05937 7.1375 9.8125C8.2375 10.2875 8.66875 10.3281 9.21875 10.2469C9.55313 10.1969 10.2437 9.82812 10.3875 9.42188C10.5312 9.01562 10.5312 8.66875 10.4875 8.59688C10.4469 8.51875 10.3313 8.475 10.1594 8.39062Z"
            fill="#000000"
          />
        </svg>
      </a>
      <div
        className={`blog-post__share--copy ${wasUrlCopied && 'copied'}`}
        onClick={() => onCopyToClipboard()}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="15" fill="none">
          <path
            fill="#F6921E"
            d="M3.014 8.404V4.362c0-1.08.691-1.776 1.776-1.776 2.046-.006 4.098-.006 6.144 0 1.096 0 1.787.697 1.787 1.787v8.055c0 1.096-.691 1.788-1.787 1.788H4.79c-1.08 0-1.776-.697-1.776-1.776V8.404Zm.871-.017v4.036c0 .646.287.933.939.933h6.099c.64 0 .933-.298.933-.945V4.356c0-.612-.304-.91-.916-.91H4.824c-.636 0-.928.292-.928.922-.011 1.337-.011 2.68-.011 4.019Z"
          />
          <path
            fill="#F6921E"
            d="M0 5.823V1.804C0 .674.68 0 1.81 0h6.183c.883 0 1.563.579 1.687 1.445a.834.834 0 0 1-.029.37c-.061.186-.219.276-.41.26-.202-.012-.337-.124-.388-.327-.017-.067-.022-.14-.028-.208a.769.769 0 0 0-.72-.674 3.154 3.154 0 0 0-.23-.006h-6.06c-.663 0-.95.287-.95.944v8.033c0 .59.237.854.821.933.248.034.416.225.4.455-.017.236-.203.4-.456.405-.792.006-1.529-.652-1.608-1.439a4.737 4.737 0 0 1-.016-.399A933.93 933.93 0 0 1 0 5.823Z"
          />
        </svg>
        <p>Copiar link</p>
        {wasUrlCopied && (
          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="7" fill="none">
            <path
              fill="#000"
              d="M3.416 5.1c.049-.082.078-.164.134-.219C5.067 3.359 6.588 1.841 8.107.319c.18-.181.379-.32.646-.319a.77.77 0 0 1 .718.43c.152.285.127.568-.057.833-.057.082-.13.154-.2.224-1.718 1.72-3.437 3.438-5.156 5.157-.473.473-.898.475-1.368.005-.8-.8-1.6-1.602-2.4-2.4-.219-.217-.337-.47-.272-.778a.793.793 0 0 1 1.3-.45c.274.234.52.503.775.758l1.323 1.32Z"
            />
          </svg>
        )}
      </div>
    </div>
  )
}

export default ShareOptions
